import { ReactNode, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Cross } from "@app/ui/icons"
import { useToggleSelector } from "@app/utils/hooks"

import { Back } from "../back"

import styles from "./modal.module.scss"

export interface ModalProps {
  children: ReactNode
  open: boolean
  onClose: () => void
  wrapperClassName?: string
  modalClassName?: string
  isCrossIconVisible?: boolean
  isGoBackButtonVisible?: boolean
  isMobileType?: boolean
  invertCrossColor?: boolean
  crossColor?: string
  crossClassName?: string
}

const Modal = ({
  children,
  open,
  onClose,
  wrapperClassName,
  modalClassName,
  crossColor,
  crossClassName = "",
  isCrossIconVisible = true,
  isGoBackButtonVisible = false,
  isMobileType = false,
  invertCrossColor = false,
}: ModalProps) => {
  const [isRenderNodeMounted, setIsRenderNodeMounted] = useState(false)
  const modalRef = useRef<HTMLElement | null>()
  const { isDragActionDown, handleTouchStart, handleTouchMove, handleTouchEnd } = useToggleSelector()

  useEffect(() => {
    modalRef.current = document.getElementById("modal")
    setIsRenderNodeMounted(true)
  }, [])

  function handleTouchCloseEnd(e) {
    handleTouchEnd(e)

    if (isDragActionDown) {
      onClose()
    }
  }

  const i18n = useI18n()

  if (isRenderNodeMounted && open) {
    return ReactDOM.createPortal(
      <aside
        className={clsx(
          styles["blackout"],
          {
            [styles["open"]]: open,
            [styles["mobile-type"]]: isMobileType,
          },
          wrapperClassName
        )}
      >
        {isMobileType && <div className={styles["back"]} onClick={onClose}></div>}
        {isGoBackButtonVisible && (
          <Back text={i18n.t("common.back")} onClick={onClose} page="photo" className={styles["go-back-button"]}></Back>
        )}
        <div className={clsx(styles["modal"], modalClassName)}>
          {isCrossIconVisible && (
            <button
              className={clsx(styles["cross"], crossClassName, {
                [styles["cross-mobile-hide"]]: isMobileType,
              })}
              onClick={onClose}
            >
              {crossColor && <Cross width={16} height={16} stroke={crossColor} />}
              {!crossColor && (
                <IconWrapper
                  light={<Cross width={16} height={16} stroke={invertCrossColor ? "#fff" : "#000"} />}
                  dark={<Cross width={16} height={16} stroke={invertCrossColor ? "#000" : "#fff"} />}
                />
              )}
            </button>
          )}
          {isMobileType && (
            <div
              className={styles["line-close"]}
              onTouchStart={(e) => {
                handleTouchStart(e)
              }}
              onTouchMove={(e) => {
                handleTouchMove(e)
              }}
              onTouchEnd={(e) => {
                handleTouchCloseEnd(e)
              }}
            ></div>
          )}
          {isGoBackButtonVisible && (
            <button className={clsx(styles["cross"], styles["cross-secondary"])} onClick={onClose}>
              {crossColor && <Cross width={16} height={16} stroke={crossColor} />}
              {!crossColor && (
                <IconWrapper
                  light={<Cross width={16} height={16} stroke="#000" />}
                  dark={<Cross width={16} height={16} stroke="#fff" />}
                />
              )}
            </button>
          )}
          {children}
        </div>
      </aside>,
      modalRef.current
    )
  }

  return null
}

export { Modal }
